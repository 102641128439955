/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.guideline-ranges[data-v-68b85ae6] {
  display: flex;
  justify-content: space-evenly;
}
.input-error[data-v-68b85ae6] {
  border: 1.5px solid #e93f6f;
}