/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.shortcuts-footer[data-v-8a8da882] {
  display: flex;
  justify-content: space-around;
}
.shortcut[data-v-8a8da882] {
  background-color: #8da8ad;
  font-size: 90%;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.shortcut[data-v-8a8da882]:hover {
  background-color: #e93f6f;
}