/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
select[data-v-6cf0ba10] {
  padding: 5px;
  border: 2px solid #ccc;
  border-radius: 2px;
  width: 100%;
  min-width: -moz-max-content;
  min-width: max-content;
}
select[data-v-6cf0ba10]:focus-within {
  outline-color: #e93f6f;
}