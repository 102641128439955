/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.message-text[data-v-0b45fbcd] {
  margin-bottom: 2rem;
}
.rag-header[data-v-0b45fbcd] {
  display: flex;
  justify-content: space-evenly;
}
.text-bold[data-v-0b45fbcd] {
  font-weight: bold;
}
.text-info[data-v-0b45fbcd] {
  color: #6c757d;
}
.date-actions[data-v-0b45fbcd] {
  display: flex;
  align-items: flex-end;
  gap: 2rem;
}
.date-actions .delete-date-btn[data-v-0b45fbcd] {
  color: #821a4d;
  margin-left: auto;
}
caption[data-v-0b45fbcd] {
  caption-side: top;
  font-weight: bold;
  padding: 1rem;
  text-align: center;
}
.cancel-btn[data-v-0b45fbcd] {
  color: #f7a600;
}
.paste-btn[data-v-0b45fbcd] {
  color: #e93f6f;
}
table[data-v-0b45fbcd] {
  border-collapse: collapse;
  text-align: left;
  vertical-align: middle;
  width: 100%;
}
table thead[data-v-0b45fbcd] {
  position: sticky;
}
table thead[data-v-0b45fbcd] {
  inset-block-start: 0;
  /* "top" */
  background: #f3f3f3;
  z-index: 1;
}
tbody tr[data-v-0b45fbcd] {
  transition: background-color 0.3s ease-in;
}
tbody tr[data-v-0b45fbcd]:hover {
  background-color: #e6e6e6;
}
tbody tr.Update[data-v-0b45fbcd] {
  outline: 1px solid lightblue;
  background: #f3f9fb;
}
tbody tr.Update[data-v-0b45fbcd]:hover {
  background: #d4ebf2;
}
th[data-v-0b45fbcd] {
  padding: 0.5rem 1rem;
  font-weight: normal;
}
th[data-v-0b45fbcd],
td[data-v-0b45fbcd] {
  border: 1px solid white;
}
td[data-v-0b45fbcd] {
  padding: 2px;
}
.name[data-v-0b45fbcd] {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 0.25rem;
  font-size: 1.2rem;
  line-height: 125%;
  --x: 0;
}
.name[data-level="0"][data-v-0b45fbcd] {
  --x: 0;
}
.name[data-level="1"][data-v-0b45fbcd] {
  --x: 1;
}
.name[data-level="2"][data-v-0b45fbcd] {
  --x: 2;
}
.name[data-level="3"][data-v-0b45fbcd] {
  --x: 3;
}
.name[data-level="4"][data-v-0b45fbcd] {
  --x: 4;
}
.name[data-level="5"][data-v-0b45fbcd] {
  --x: 5;
}
.name[data-v-0b45fbcd]::before {
  content: "";
  width: calc(1.5rem * var(--x));
  display: inline-block;
}
.not-set-btn[data-v-0b45fbcd] {
  color: #e93f6f;
}
.delete-btn[data-v-0b45fbcd] {
  color: #821a4d;
}
.undo-btn[data-v-0b45fbcd] {
  color: #00a6c9;
}
.save-slide-out[data-v-0b45fbcd] {
  z-index: 1;
  width: 85%;
  position: fixed;
  transform: translateY(8rem);
  bottom: 10px;
  transition: transform 0.3s ease-in;
  right: 0;
}
.save-slide-out.show-save[data-v-0b45fbcd] {
  transform: translateY(0rem);
}
.save-slide-out .slide-out-inner[data-v-0b45fbcd] {
  width: 360px;
  margin: 0 auto;
  padding: 1.25rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.17);
}
.save-slide-out .slide-out-inner .btn[data-v-0b45fbcd]:first-of-type {
  margin-right: 1rem;
}
.market-cashflow-value[data-v-0b45fbcd] {
  padding: 2rem 2rem 10rem;
  background-color: #f3f3f3;
}
.market-cashflow-value.no-data[data-v-0b45fbcd] {
  width: 100%;
  height: 100%;
}
.market-cashflow-value .add-column[data-v-0b45fbcd] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 16rem;
  padding: 0 0.5rem;
  border: solid white 1px;
  background: #e93f6f0a;
  position: relative;
}
.market-cashflow-value .add-column .add-wrapper[data-v-0b45fbcd] {
  position: sticky;
  top: 50px;
  bottom: 50px;
}
.market-cashflow-value .add-column .add-wrapper div[data-v-0b45fbcd] {
  margin: 0 auto;
}
.market-cashflow-value .add-column span[data-v-0b45fbcd] {
  margin-top: 10px;
  font-size: 13px;
  color: #a1a1a1;
  font-weight: bold;
  text-transform: uppercase;
}
.centerY[data-v-0b45fbcd] {
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
}
.bg-grey[data-v-0b45fbcd] {
  background: #f3f3f3;
}
.min-h-100[data-v-0b45fbcd] {
  min-height: 100%;
}
.mt-3[data-v-0b45fbcd] {
  margin-top: 2rem;
}
.text-center[data-v-0b45fbcd] {
  text-align: center;
}
.gap[data-v-0b45fbcd] {
  gap: 1rem;
}
.date-actions-center[data-v-0b45fbcd] {
  justify-content: center;
  align-items: center;
}
.status-message[data-v-0b45fbcd] {
  text-align: center;
  color: #d2222d;
  padding: 1rem;
}
.nav-item[data-v-0b45fbcd] {
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}