/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
input[data-v-211207fd] {
  text-align: right;
  border: 2px solid #ccc;
  border-radius: 2px;
  text-align: right;
  width: 8rem;
  padding: 0.5rem 0;
  flex: 1;
  padding-left: 30px;
}
input[data-v-211207fd]:focus {
  outline: none;
  border-color: #e93f6f;
}
.input-wrapper[data-v-211207fd] {
  position: relative;
  display: flex;
  align-items: center;
}
input.input-error[data-v-211207fd] {
  border: 2px solid #821a4d;
}
.error-icon[data-v-211207fd] {
  position: absolute;
  left: 10px;
  color: #821a4d;
}