/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.modal[data-v-e5e623c4] {
  padding: 1rem 2rem;
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 1rem;
}
.modal .modal-header[data-v-e5e623c4] {
  font-size: 1.6rem;
  font-weight: 600;
}
.modal .modal-body[data-v-e5e623c4] {
  display: grid;
  grid-template-rows: repeat(2, auto);
  gap: 1rem;
  max-height: 50svh;
  overflow: auto;
}
.modal .modal-footer[data-v-e5e623c4] {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}